// 站点分析
import request from '@/utils/request'

// 初始化查询
export function getPageInit() {
    return request({
       url: "/api/amazon/marketSales/pageInit",
       method: "get"
    })
}

// 查询事件
export function handleSearch(data) {
    return request({
       url: "/api/amazon/marketSales/list",
       data,
       method: "post"
    })
}